<template>
  <main>
    <trac-loading v-if="isLoading"></trac-loading>
    <div v-else>
      <h1 class="text-2xl font-bold">Account List</h1>
      <div class="flex justify-end items-end">
        <button
          class="bg-blue-600 py-3 px-6 rounded-md text-white"
          @click="downloadCSV"
        >
          Download CSV
        </button>
      </div>
      <div class="flex items-center">
        <input
          type="text"
          class="inline-block px-2 ring-2 outline-none ring-blue-500 rounded-sm ml-2 w-64 text-sm py-2"
          name=""
          placeholder="Search by name, email"
          id=""
          v-model="search"
        />
        <button
          @click="searchRecords"
          class="block mx-2 uppercase shadow bg-blue-800 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white text-xs py-3 px-8 rounded"
        >
          Search
        </button>
        <button
          @click="clearSearchRecords"
          class="block mx-2 uppercase shadow bg-red-800 hover:bg-red-700 focus:shadow-outline focus:outline-none text-white text-xs py-3 px-8 rounded"
        >
          Reset
        </button>
      </div>
      <div
        class="flex justify-between items-center m-3 flex-grow-0 flex-shrink"
      >
        <div class="">
          <div class="flex time-filter">
            <span>From: &nbsp;</span>
            <input
              type="date"
              class="inline-block border-2 px-2 rounded-sm mr-2"
              id=""
              v-model="dateData.startDate"
            />
            <!-- <input
              type="time"
              class="inline-block border-2 px-2 rounded-sm mr-2"
              name=""
              id=""
              v-model="dateData.startTime"
            /> -->
          </div>
          <div class="flex time-filter mt-2">
            <span>To: &nbsp;</span>
            <input
              type="date"
              class="inline-block border-2 px-2 rounded-sm mr-2"
              name=""
              id=""
              v-model="dateData.endDate"
            />
            <!-- <input
              type="time"
              class="inline-block border-2 px-2 rounded-sm mr-2"
              name=""
              id=""
              v-model="dateData.endTime"
            /> -->
          </div>
          <div class="flex mt-3">
            <button
              @click="fetchDatedRecords"
              class="block mr-2 uppercase shadow bg-blue-800 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white text-xs py-2 px-8 rounded"
            >
              Get Records
            </button>
            <button
              @click="clearDate"
              class="block mr-2 uppercase shadow bg-red-800 hover:bg-red-700 focus:shadow-outline focus:outline-none text-white text-xs py-2 px-8 rounded"
            >
              Reset
            </button>
          </div>
        </div>
      </div>
      <div class="mt-10 pb-5" style="height: 600px; overflow: auto">
        <table class="shadow-lg bg-white w-full">
          <thead>
            <tr>
              <th
                class="text-l p-3 text-left"
                colspan="11"
                style="background: #dee6f2"
              >
                Agent's Information
              </th>
            </tr>
            <tr>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                First Name
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Last Name
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                ID
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Email
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Phone Number
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Address
              </th>
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Vertical
              </th>

              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Sign up Date
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(account, i) in filterAccounts" :key="i">
              <td
                class="border px-8 py-3 whitespace-nowrap text-xs"
                style="white-space: inherit"
              >
                {{
                  account.userProfile !== undefined &&
                  account.userProfile.firstName
                }}
              </td>
              <td
                class="border px-8 py-3 whitespace-nowrap text-xs"
                style="white-space: inherit"
              >
                {{
                  account.userProfile !== undefined &&
                  account.userProfile.lastName
                }}
              </td>
              <td
                class="border px-8 py-3 whitespace-nowrap text-xs"
                style="white-space: inherit"
              >
                {{ account.userId }}
              </td>
              <td
                class="border px-8 py-3 whitespace-nowrap text-xs"
                style="white-space: inherit"
              >
                {{
                  account.userProfile !== undefined && account.userProfile.email
                }}
              </td>
              <td
                class="border px-8 py-3 whitespace-nowrap text-xs"
                style="white-space: inherit"
              >
                {{ account.phoneNumber }}
              </td>
              <td class="border px-8 py-3 text-xs">
                {{
                  (account.address &&
                    account.address +
                      " " +
                      account.state +
                      " " +
                      account.lga +
                      " " +
                      account.city +
                      " " +
                      account.country) ||
                  "N/A"
                }}
              </td>
              <td
                class="border px-8 py-3 whitespace-nowrap text-xs"
                style="white-space: inherit"
              >
                {{ account.vertical || "N/A" }}
              </td>

              <td
                class="border px-8 py-3 whitespace-nowrap text-xs"
                style="white-space: inherit"
              >
                {{
                  moment(account.createdAt).format("DD MMM YYYY - h:mm:ss a")
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="mt-3">
        <button
          @click="prevPage(currentPage)"
          :disabled="currentPage === 1 ? isDisabled : !isDisabled"
          class="uppercase shadow ml-3 hover:bg-blue-600 focus:shadow-outline focus:outline-none text-xs font-bold py-3 px-8 rounded"
          :class="
            currentPage === 1
              ? 'bg-blue-50 text-black'
              : 'bg-blue-900 text-white'
          "
        >
          &laquo; Prev
        </button>

        <button
          @click="nextPage(currentPage)"
          v-if="filterAccounts.length > 99"
          class="float-right uppercase shadow ml-3 hover:bg-blue-600 bg-blue-900 focus:shadow-outline focus:outline-none text-white text-xs font-bold py-3 px-8 rounded"
        >
          Next &raquo;
        </button>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import { json2excel } from "js2excel";
export default {
  data() {
    return {
      moment,
      isLoading: false,
      search: "",
      accounts: null,
      currentPage: 1,
      dateData: null,
      isDisabled: true,
      dateFilterChecker: false,
      searchActive: false,
    };
  },
  computed: {
    ...mapGetters(["GET_CSR_ACCOUNT_LIST"]),
    filterAccounts() {
      if (
        this.GET_CSR_ACCOUNT_LIST.data !== undefined &&
        this.GET_CSR_ACCOUNT_LIST.data !== null
      ) {
        return this.GET_CSR_ACCOUNT_LIST.data;
      } else {
        return [];
      }
    },
  },
  async mounted() {
    this.fetchRecords();
  },
  async created() {
    const d = new Date();
    this.dateData = {
      startDate: new Date(d.setDate(d.getDate() - 1)).toJSON().split("T")[0],
      endDate: new Date().toJSON().split("T")[0],
      startTime: "00:00",
      endTime: this.moment(d).format("HH:mm"),

      // enddatetime: new Date().toJSON().split("T")[0]
    };
  },
  filters: {
    truncate: function (text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
  },
  methods: {
    async fetchRecords() {
      this.isLoading = true;
      let payload = {
        page: this.currentPage,
        dateFilterChecker: this.dateFilterChecker,
        date: this.dateData,
        searchActive: this.searchActive,
        search: this.search,
      };
      try {
        let res = await this.$store.dispatch("FETCH_CSR_ACCOUNT_LIST", payload);
        this.isLoading = false;
        if (res.status) {
          this.isLoading = false;
        } else {
          alert(
            res.error ||
              res.message ||
              "Error: Please check network or contact admin."
          );
        }
      } catch (error) {}
    },
    async fetchDatedRecords() {
      this.isLoading = true;
      this.dateFilterChecker = true;
      this.currentPage = 1;
      let payload = {
        page: this.currentPage,
        dateFilterChecker: this.dateFilterChecker,
        date: this.dateData,
        searchActive: this.searchActive,
        search: this.search,
      };
      try {
        let res = await this.$store.dispatch("FETCH_CSR_ACCOUNT_LIST", payload);
        this.isLoading = false;

        if (res.status) {
          this.accounts = res.data;
        } else {
          alert(
            res.error ||
              res.message ||
              "Error: Please check network or contact admin."
          );
          $router.go(-1);
        }
      } catch (error) {}

      this.isLoading = false;
    },
    async clearSearchRecords() {
      this.currentPage = 1;
      this.search = "";
      this.searchActive = false;
      this.fetchRecords();
    },

    async clearDate() {
      this.currentPage = 1;
      this.dateFilterChecker = false;
      const d = new Date();
      this.dateData = {
        startDate: new Date(d.setDate(d.getDate() - 1)).toJSON().split("T")[0],
        endDate: new Date().toJSON().split("T")[0],
        startTime: "00:00",
        endTime: this.moment(d).format("HH:mm"),
      };
      this.fetchRecords();
    },
    async downloadCSV() {
      this.isLoading = true;
      let payload = {
        dateFilterChecker: this.dateFilterChecker,
        date: this.dateData,
        searchActive: this.searchActive,
        search: this.search,
      };
      let res = await this.$store.dispatch(
        "DOWNLOAD_CSR_ACCOUNT_LIST_CSV",
        payload
      );
      this.isLoading = false;
      if (res.status) {
        this.isLoading = false;
        window.open(res.data, "_blank");
        console.log(this.isLoading);
      } else {
        this.isLoading = false;
      }
    },
    async prevPage() {
      this.isLoading = true;
      let payload = {
        page: this.currentPage - 1,
        dateFilterChecker: this.dateFilterChecker,
        date: this.dateData,
        searchActive: this.searchActive,
        search: this.search,
      };
      let res = await this.$store.dispatch("FETCH_CSR_ACCOUNT_LIST", payload);
      if (res.status) {
        this.isLoading = false;
        this.currentPage -= 1;
      } else {
        this.isLoading = false;
      }
    },
    async nextPage() {
      this.isLoading = true;
      let payload = {
        page: this.currentPage + 1,
        dateFilterChecker: this.dateFilterChecker,
        date: this.dateData,
        searchActive: this.searchActive,
        search: this.search,
      };
      let res = await this.$store.dispatch("FETCH_CSR_ACCOUNT_LIST", payload);
      if (res.status) {
        this.isLoading = false;
        this.currentPage += 1;
      } else {
        this.isLoading = false;
      }
    },
    async searchRecords() {
      this.isLoading = true;
      this.searchActive = true;
      let payload = {
        page: 1,
        dateFilterChecker: this.dateFilterChecker,
        date: this.dateData,
        searchActive: this.searchActive,
        search: this.search,
      };
      let res = await this.$store.dispatch("FETCH_CSR_ACCOUNT_LIST", payload);
      this.isLoading = false;
      if (res.status) {
        this.isLoading = false;
      } else {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
table {
  border-collapse: separate;
  border-spacing: 0;
  min-width: 350px;
}
table tr th:last-child {
  border-right: 1px solid rgb(241, 241, 241);
}
table tr th,
table tr td {
  /* border-right: 1px solid #bbb; */
  border-bottom: 1px solid rgb(241, 241, 241);
  padding: 18px;
}
table tr th:first-child,
table tr td:first-child {
  /* border-right: 1px solid rgb(223, 223, 223); */
  border-left: 1px solid rgb(241, 241, 241);
}
table tr th {
  background: rgb(255, 255, 255);
  border-top: 1px solid rgb(241, 241, 241);
  text-align: left;
}
table tr td {
  background: #f0f6fd;
}
/* top-left border-radius */
table tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

/* top-right border-radius */
table tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

/* bottom-left border-radius */
table tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
table tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}
</style>
